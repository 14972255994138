
const config = {

    SERVER_SOCKET:'wss://quizserver.vityazgroup.ru:8500',
    URL_API:'https://quizserver.vityazgroup.ru:8500/api/',
    // URL_API:'http://192.168.1.65:8011/api/',
    // SERVER_SOCKET:'ws://192.168.1.65:8100',
    // SERVER_SOCKET:'ws://127.0.0.1:8100',


    URL_LOGIN:'auth/login',
}

export default config
